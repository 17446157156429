import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { encode } from 'base-64';
// import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format, addDays, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Divider, Tooltip, Typography } from '@mui/material';
import { dispatch } from 'redux/store';
import { getDirectorDashboard } from 'redux/slices/director-dashboard';
import { weekdays } from 'moment';
import Button from '@mui/material/Button';
import { getAllHomeworkList, resetState } from '../../../redux/slices/homework';
import HomeworkForm from '../../../components/_dashboard/teacher/HomeworkForm';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import EditHomeworkForm from 'components/_dashboard/teacher/EditHomeworkForm';
import edit2Outline from '@iconify/icons-eva/edit-2-outline';
import { createInputGrade, getAllGradeList, getGradeList } from '../../../redux/slices/grade';
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { AddCircle } from '@mui/icons-material';
import HomeworkDeleteConfirmationDialog from '../../../components/_dashboard/teacher/HomeworkDeleteConfirmationDialog';

import {
  deleteHomeworkPoint,
  getAllStudentList,
  getAllStudentListSuccess,
  getGrade,
  getGradePoinData,
  getHomeworkDates,
  getStudentGradePoint,
  getStudentNameList
} from 'redux/slices/student';
import EditGradeForm from 'components/_dashboard/teacher/EditGradeForm';
import { ListQueryParams } from '../../../@types/table';
import { IconButton } from '@mui/material';
import { Box } from '@mui/material';
import { Root } from 'rehype-raw';
import DuplicateHomeeworkForm from 'components/_dashboard/teacher/DuplicateHomeworkform';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createGrade } from '../../../redux/slices/grade';
import { startOfWeek, eachDayOfInterval } from 'date-fns';
// import moment from 'moment';
import { useParams } from 'react-router';
import { getAllClassList, getClassList, getProgress } from 'redux/slices/class';
import { createClass, getClassByID, updateClass } from '../../../redux/slices/class';
import { aC } from '@fullcalendar/core/internal-common';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import plusFill from '@iconify/icons-eva/plus-fill';
import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';
import useAuth from 'hooks/useAuth';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

type CalenderFormProps = {
  editId: number | null;
};

export default function DirectorDashboardList({ editId }: CalenderFormProps) {
  const [data, setData] = useState<any>({});
  console.log('EditId', editId);
  const [name, setName] = useState<string[]>([]);

  const [displayDays, setDisplayDays] = useState({});
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);
  const {
    grade: gradeObject,
    error: gradeError,
    response: gradeResponse
  } = useSelector((state: RootState) => state.grade);
  const [errormessage, setErrorMessage] = useState('');
  console.log(errormessage);
  useEffect(() => {
    if (gradeError) {
      setErrorMessage(gradeError.message);
    }
  }, [gradeError]);
  const [openHomeworkForm, setOpenHomeworkForm] = useState(false);
  const [openDuplicateForm, setOpenDuplicateForm] = useState(false);
  const [selectedHomeworkId, setSelectedHomeworkId] = useState<string>('');
  const [selectedDuplicateHomeworkId, setSelectedDuplicateHomeworkId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState('');

  const [openDelConf, setOpenDelConf] = useState(false);
  const [rows, setRows] = useState<any>([]);
  console.log('ROws', rows);
  const [calendarEvents, setCalendarEvents] = useState<any>([]);
  const [titleByDate, setTitlesByDate] = useState<any>([]);
  const [pointByDate, setPointByDate] = useState<any>([]);

  const [chapterByDate, setChapterByDate] = useState<any>([]);
  const { homeworkList } = useSelector((state: RootState) => state.homework);
  console.log('HomeworkList', homeworkList);
  const { progressPoint } = useSelector((state: RootState) => state.class);
  console.log('classProgress', progressPoint);
  const { classList } = useSelector((state: RootState) => state.class);
  console.log('ClassList', classList);
  const { studentLists } = useSelector((state: RootState) => state.student);
  console.log('StudentList', studentLists);
  const [openGradeForms, setOpenGradeForms] = useState(false);
  const [selectedGradeId, setSelectedGradeId] = useState<number | null>(null);
  const [queryParams, setQueryParams] = useState<ListQueryParams>({
    page: 1,
    pageSize: 5
  });
  const { gradeList, gradeListMeta } = useSelector((state: RootState) => state.grade);
  const [openEditGradeForm, setOpenEditGradeForm] = useState(false);
  const [editCell, setEditCell] = useState({ rowIndex: null, dateIndex: null });
  const [newPoint, setNewPoint] = useState('');

  const [classId, setClassId] = useState<any | null>(null);
  const [classID, setClassID] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    class: classObject,
    error: classError,
    response: classResponse
  } = useSelector((state: RootState) => state.class);
  const [editData, setEditData] = useState(classObject);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (errormessage) {
      enqueueSnackbar(errormessage ? errormessage : 'Create success', {
        variant: 'error'
        // action: (key) => (
        //   <MIconButton size="small">
        //     <Icon icon={closeFill} />
        //   </MIconButton>
        // )
      });
      //   processedSuccess();
      //   handleClose();
    }
  }, [errormessage]);
  useEffect(() => {
    if (editId !== null) {
      setIsLoading(true);

      dispatch(getClassByID(editId))
        .then((response: any) => {
          const classIdFromResponse = response?.data?.classId || editId;
          setClassID(classIdFromResponse);

          dispatch(getDirectorDashboard(classIdFromResponse))
            .then((dashboardResponse: any) => {
              setData(dashboardResponse.data[0] || {});
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setEditData(null);
    }
  }, [editId, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      // await dispatch(getAllHomeworkList(editId));
      const today = new Date();

      // Calculate the start of the current week (Monday)
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Adjust to Monday (0 = Sunday)

      // Calculate the end of the current week (Sunday)
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() - today.getDay() + 7); // Adjust to Sunday

      // Format the dates as 'YYYY-MM-DD'
      const formatDate = (date: any) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const startDate = formatDate(startOfWeek);
      const endDate = formatDate(endOfWeek);

      console.log('Current Week Start:', startDate);
      console.log('Current Week End:', endDate);

      // Dispatch the action with the calculated dates
      await dispatch(getAllHomeworkList(editId, startDate, endDate));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchClassList = async () => {
      await dispatch(getClassList(queryParams));
    };
    fetchClassList();
  }, [dispatch]);

  useEffect(() => {
    if (classList.length > 0) {
      const selectedClassId = classList[0]?.id || null;
      setClassId(selectedClassId);
    }
  }, [classList]);

  useEffect(() => {
    const events = homeworkList.map((h: any) => ({
      id: h.id,
      title: h.title,
      start: h.date,
      end: h.date,
      allDay: true,
      backgroundColor: '#3788d8',
      borderColor: '#3788d8'
    }));

    setCalendarEvents(events);

    const titlesByDate = homeworkList.reduce((acc: any, h: any) => {
      // const formattedDate = format(new Date(h.date), 'yyyy-MM-dd');
      const formattedDate = moment.utc(h.date).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ title: h.title, id: h.id });
      return acc;
    }, {});

    const chaptersByDate = homeworkList.reduce((acc: any, h: any) => {
      // const formattedDate = format(new Date(h.date), 'yyyy-MM-dd');
      const formattedDate = moment.utc(h.date).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ chapter: h.chapter, id: h.id });
      return acc;
    }, {});
    const PointsByDate = homeworkList.reduce((acc: any, h: any) => {
      // const formattedDate = format(new Date(h.date), 'yyyy-MM-dd');
      const formattedDate = moment.utc(h.date).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ point: h.point, id: h.id });
      return acc;
    }, {});

    setTitlesByDate(titlesByDate);
    setChapterByDate(chaptersByDate);
    setPointByDate(PointsByDate);
  }, [homeworkList]);

  const handleCellClick = (rowIndex: any, dateIndex: any) => {
    setEditCell({ rowIndex, dateIndex });
  };
  const [error, setError] = useState<string | null>(null);

  // const handleInputChange = (e: any) => {
  //   setNewPoint(e.target.value);
  // };
  const handleInputChange = (e: any) => {
    setNewPoint(e.target.value);
  };

  const handleOpenUrl = (recordId: any) => {
    // setOpenUrlForm(true);
    const encryptedRecordId = encode(recordId.toString());

    // const urlToCopy = `http://localhost:8000/dashboard/student/rank/${encryptedRecordId}`;
    const urlToCopy = `${window.location.origin}/rank/${encryptedRecordId}`;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        alert('URL copied to clipboard!');
        // window.location.href = urlToCopy;
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleInputBlur = async (rowIndex: any, dateIndex: any) => {
    if (newPoint) {
      const date = weekDates[dateIndex];
      const formattedDate = format(new Date(date), 'yyyy-MM-dd');

      const row = rows[rowIndex];

      const studentId = row.id;

      const homeworkDate = formattedDate;

      const homeworkDetails = titleByDate[formattedDate] || [];

      const homeworkId = homeworkDetails.length > 0 ? homeworkDetails[0].id : 'No Title';

      const pointData = {
        point: newPoint,
        student_id: studentId,
        homework_id: homeworkId
      };

      try {
        await dispatch(createInputGrade(pointData, editId));

        await dispatch(getGradePoinData(editId));

        setNewPoint(pointData.point);
        setEditCell({ rowIndex: null, dateIndex: null });
      } catch (error: any) {
        console.error('Error posting point data:', error);
      }
    }
  };

  const handleDateRangeChange = (newRange: [Date | null, Date | null]) => {
    setDateRange(newRange);
  };

  const getMonthDates = (start: Date, end: Date) => {
    const dates = [];
    for (let date = start; date <= end; date = addDays(date, 1)) {
      dates.push(format(date, 'yyyy-MM-dd'));
    }

    return dates;
  };

  const monthDates = useMemo(() => {
    const start = dateRange[0] || startOfMonth(new Date());
    const end = dateRange[1] || endOfMonth(new Date());
    return getMonthDates(start, end);
  }, [dateRange]);

  const { user } = useAuth();
  console.log(user);
  useEffect(() => {
    const fetchProgress = async () => {
      const result = await dispatch(getProgress(user?.classId ? user?.classId : editId));
      console.log('Res', result);
    };
    fetchProgress();
  }, [studentLists, homeworkList]);

  const filteredDates = useMemo(() => {
    return monthDates.filter((date) => {
      const dateObj = new Date(date);

      return isWithinInterval(dateObj, {
        start: dateRange[0] || startOfMonth(new Date()),
        end: dateRange[1] || endOfMonth(new Date())
      });
    });
  }, [dateRange, monthDates]);

  const handleOpenCreateForm = (date: any) => {
    setSelectedDate(date);

    setOpenHomeworkForm(true);
  };
  const handleEditHomework = (homeworkId: any) => {
    setSelectedHomeworkId(homeworkId);
    setOpenHomeworkForm(true);
  };
  const handleDuplicateHomework = (homeworkId: string) => {
    setSelectedDuplicateHomeworkId(homeworkId);
    setOpenDuplicateForm(true);
  };
  const handleHomeworkFormClosed = () => {
    setOpenHomeworkForm(false);
    setSelectedHomeworkId('');
    dispatch(resetState());
  };
  const handleDuplicateHomeworkForm = () => {
    setOpenDuplicateForm(false);
    setSelectedDuplicateHomeworkId('');
    dispatch(resetState());
  };
  const handleHomeworkFormProcessedSuccess = () => {
    dispatch(getAllHomeworkList(editId));
    dispatch(getGradePoinData(editId));
  };

  const handleDeleteHomework = async (homeworkId: string) => {
    setOpenHomeworkForm(false);
    setSelectedHomeworkId(homeworkId);
    setOpenDelConf(true);
    await dispatch(deleteHomeworkPoint());
    await dispatch(getGradePoinData(editId));
  };
  const handleDeletingCancelled = () => {
    setOpenDelConf(false);
    setSelectedHomeworkId('');
    dispatch(resetState());
  };

  const handleDeleted = () => {
    setOpenDelConf(false);
    setSelectedHomeworkId('');
    dispatch(getAllHomeworkList(editId));
    dispatch(getGradePoinData(editId));
  };
  const handleOpenCreateForms = () => {
    setOpenGradeForms(true);
  };
  const handleGradeFormClosed = () => {
    setOpenGradeForms(false);
    setSelectedGradeId(null);
    dispatch(resetState());
  };
  const handleGradeFormProcessedSuccess = () => {
    dispatch(getGradeList(queryParams));
  };
  const handleEditGradeFormClosed = () => {
    setOpenEditGradeForm(false);
    setSelectedGradeId(null);
    dispatch(resetState());
  };
  const handleEditRecord = (gradeId: any) => {
    setSelectedGradeId(gradeId);
    setOpenEditGradeForm(true);
  };

  useEffect(() => {
    const fetchGradePoint = async () => {
      // await dispatch(getGradePoinData(editId));
      const currentWeekStart = moment().startOf('week').add(1, 'days'); // Get Monday
      const currentWeekEnd = moment(currentWeekStart).add(6, 'days');
      const startDate = currentWeekStart.format('YYYY-MM-DD');
      const endDate = currentWeekEnd.format('YYYY-MM-DD');

      // Dispatch the action with the current week start and end dates
      await dispatch(getGradePoinData(editId, startDate, endDate));
    };
    fetchGradePoint();
  }, [dispatch, editId]);

  useEffect(() => {
    if (!Array.isArray(studentLists)) {
      return;
    }

    const formattedRows = studentLists?.map((student: any) => {
      const pointsMap = Array.isArray(student.points)
        ? student.points?.reduce((acc: any, pointData: any) => {
            if (pointData?.date && pointData?.point != null) {
              const adjustedDate = pointData.date;

              acc[adjustedDate] = {
                point: pointData.point,
                title: pointData.title,
                id: pointData.id
              };
            }

            return acc;
          }, {})
        : {};

      return {
        fullName: student.fullName,
        id: student.id,
        points: pointsMap
      };
    });

    const sortedRows = formattedRows.sort((a, b) => a.fullName.localeCompare(b.fullName));

    setRows(sortedRows);
  }, [studentLists]);

  const formatDate = (isoDateString: any) => {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());
  console.log('selectedWeekStrta', selectedWeekStart);
  const [weekRange, setWeekRange] = useState('');
  console.log('WeekRange', weekRange);
  const getWeekDates = (startDate: any) => {
    const weekDates = [];

    const mondayDate = moment(startDate).startOf('week').add(1, 'days');

    for (let i = 0; i < 7; i++) {
      const date = moment(mondayDate).add(i, 'days');

      const formattedDate = date.format('YYYY-MM-DD');

      weekDates.push(formattedDate);
    }
    return weekDates;
  };
  const calculateWeekRange = (startDate: any) => {
    const weekStart = moment(startDate).startOf('week').add(1, 'days'); // Monday as the start
    const weekEnd = weekStart.clone().add(6, 'days'); // Sunday as the end

    return `${weekStart.format('D MMM')} to ${weekEnd.format('D MMM')}`;
  };

  useEffect(() => {
    const formattedWeekRange = calculateWeekRange(moment());
    setWeekRange(formattedWeekRange);
  }, []);

  const handlePreviousWeek = async () => {
    // const previousWeekStart = moment(selectedWeekStart).subtract(1, 'week');

    // setSelectedWeekStart(previousWeekStart.toDate());
    const previousWeekStart = moment(selectedWeekStart).subtract(1, 'week');

    const previousWeekEnd = moment(previousWeekStart).add(6, 'days');
    const formattedWeekRange = calculateWeekRange(previousWeekStart);
    setSelectedWeekStart(previousWeekStart.toDate());
    setWeekRange(formattedWeekRange);
    const startDate = previousWeekStart.format('YYYY-MM-DD');
    const endDate = previousWeekEnd.format('YYYY-MM-DD');

    await dispatch(getAllHomeworkList(editId, startDate, endDate));
    await dispatch(getGradePoinData(editId, startDate, endDate));
  };

  const handleNextWeek = async () => {
    // const nextWeekStart = moment(selectedWeekStart).add(1, 'week');
    // setSelectedWeekStart(nextWeekStart.toDate());
    const nextWeekStart = moment(selectedWeekStart).add(1, 'week');

    const nextWeekEnd = moment(nextWeekStart).add(6, 'days');
    const formattedWeekRange = calculateWeekRange(nextWeekStart);
    setSelectedWeekStart(nextWeekStart.toDate());
    setWeekRange(formattedWeekRange);
    const startDate = nextWeekStart.format('YYYY-MM-DD');
    const endDate = nextWeekEnd.format('YYYY-MM-DD');

    console.log('Next Week Start:', startDate);
    console.log('Next Week End:', endDate);

    await dispatch(getAllHomeworkList(editId, startDate, endDate));
    await dispatch(getGradePoinData(editId, startDate, endDate));
  };

  const handleCurrentWeek = async () => {
    // const currentWeekStart = moment().startOf('week').add(1, 'days');

    // setSelectedWeekStart(currentWeekStart.toDate());
    const currentWeekStart = moment().startOf('week').add(1, 'days');

    const currentWeekEnd = moment(currentWeekStart).add(6, 'days');
    const formattedWeekRange = calculateWeekRange(currentWeekStart);
    setSelectedWeekStart(currentWeekStart.toDate());
    setWeekRange(formattedWeekRange);
    const startDate = currentWeekStart.format('YYYY-MM-DD');
    const endDate = currentWeekEnd.format('YYYY-MM-DD');

    console.log('Current Week Start:', startDate);
    console.log('Current Week End:', endDate);

    await dispatch(getAllHomeworkList(editId, startDate, endDate));
    await dispatch(getGradePoinData(editId, startDate, endDate));
  };

  const weekDates = getWeekDates(selectedWeekStart);
  const calculatePercentage = (min: any, max: any) => {
    const count = progressPoint.filter(
      (progress: any) => progress.totalEarnedPoints >= min && progress.totalEarnedPoints <= max
    ).length;

    return ((count / progressPoint.length) * 100).toFixed(2);
  };

  const percentage0to50 = calculatePercentage(0, 50);
  console.log(percentage0to50);
  const percentage50to100 = calculatePercentage(51, 100);
  console.log(percentage50to100);
  const percentage100Plus = calculatePercentage(101, Infinity);
  console.log(percentage100Plus);
  const CircularProgressWithSegments = ({
    percentage0to50,
    percentage50to100,
    percentage100Plus
  }: {
    percentage0to50: number;
    percentage50to100: number;
    percentage100Plus: number;
  }) => {
    const getDashArray = (percentage: number) => {
      return (percentage / 100) * 2 * Math.PI * 50;
    };

    const redDashArray = getDashArray(percentage0to50);
    const orangeDashArray = getDashArray(percentage50to100);
    const greenDashArray = getDashArray(percentage100Plus);

    return (
      <svg width="92" height="92" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="50" stroke="#fff" strokeWidth="10" fill="transparent" />
        {/* Red */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="red"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${redDashArray} ${2 * Math.PI * 50 - redDashArray}`}
          strokeDashoffset="90"
        />
        {/* Orange  */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="orange"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${orangeDashArray} ${2 * Math.PI * 50 - orangeDashArray}`}
          strokeDashoffset={`${90 - redDashArray}`}
          // strokeDashoffset={`${90 - redDashArray - gap}`}
        />
        {/* Green */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="green"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${greenDashArray} ${2 * Math.PI * 50 - greenDashArray}`}
          strokeDashoffset={`${90 - redDashArray - orangeDashArray}`}
          // strokeDashoffset={`${90 - redDashArray - orangeDashArray - gap * 2}`}
        />
      </svg>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          p: 0.5,
          px: '4px',
          mt: -3,
          right: 25,
          bottom: '10%',
          color: 'common.white',
          position: 'fixed',
          bgcolor: 'info.main',
          borderRadius: '24px',
          zIndex: '9',
          boxShadow: (theme) => theme.customShadows.z12
        }}
      >
        <Tooltip title="Create Class">
          <MIconButton
            color="inherit"
            onClick={handleOpenCreateForm}
            sx={{
              p: 0,
              width: 40,
              height: 40,
              transition: (theme) => theme.transitions.create('all'),
              '&:hover': { color: 'primary.main', bgcolor: 'transparent' }
            }}
          >
            <Icon icon={plusFill} width={20} height={20} />
          </MIconButton>
        </Tooltip>
      </Box>

      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 500,
          marginLeft: { xs: '20px', sm: '120px' },
          marginTop: '50px',
          fontFamily: 'Inter, sans-serif'
        }}
      >
        Progress
      </Typography>

      {/* First Part */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',

          marginX: { xs: '10px', sm: '20px', md: '117px' },
          overflowX: 'auto',
          overflowY: 'auto',
          marginTop: '36px',
          // flexWrap: 'nowrap',
          marginBottom: '10px',
          // overflow: 'hidden',
          borderRadius: '9px'
          // flexWrap: 'wrap'
        }}
      >
        {/* Circle Progress */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '23px',
            marginLeft: '24px',
            marginTop: '28px'
          }}
        >
          <CircularProgressWithSegments
            percentage0to50={parseFloat(percentage0to50)}
            percentage50to100={parseFloat(percentage50to100)}
            percentage100Plus={parseFloat(percentage100Plus)}
          />
        </Box>

        {/* Total student and Point */}
        <Box
          sx={{
            alignItems: 'center',

            fontWeight: 'bold',

            marginTop: { xs: '10px', sm: '20px' },
            marginLeft: { xs: '8px', sm: '13px' }
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: { xs: '8px', sm: '16px' },
              fontSize: { xs: '20px', sm: '24px' },
              textAlign: 'center',
              fontWeight: 500,
              fontFamily: 'Inter, sans-serif',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {progressPoint.reduce((sum: any, progress: any) => sum + progress.totalEarnedPoints, 0)}{' '}
            points
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '20px',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Inter, sans-serif'
            }}
          >
            {progressPoint.length} Students
          </Typography>
        </Box>

        <Box
          sx={{
            borderLeft: '2px solid #D9D9D9',
            height: '100px',
            marginTop: { xs: '0px', sm: '15px' },
            marginBottom: { xs: '0px', sm: '15px' },
            marginLeft: '60px',
            display: 'inline-block'
          }}
        />
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '9px',
            // marginX: '117px',
            padding: '16px'
          }}
        >
          {/* 0-50 points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'red',
                borderWidth: '1px',
                marginTop: '5px',

                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '25px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              0-50 points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '10px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage0to50}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              (
              {
                progressPoint.filter(
                  (progress: any) =>
                    progress.totalEarnedPoints >= 0 && progress.totalEarnedPoints <= 50
                ).length
              }
              )
            </Typography>
          </Box>

          {/* 50-100 points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'orange',
                borderWidth: '1px',
                marginTop: '5px',
                marginRight: '7px',
                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '20px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              50-100 points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '25px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage50to100}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              (
              {
                progressPoint.filter(
                  (progress: any) =>
                    progress.totalEarnedPoints > 50 && progress.totalEarnedPoints <= 100
                ).length
              }
              )
            </Typography>
          </Box>

          {/* 100+ points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'green',
                borderWidth: '1px',
                marginTop: '5px',
                // marginRight: '3px',
                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '30px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              100+ points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '10px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage100Plus}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              ({progressPoint.filter((progress: any) => progress.totalEarnedPoints > 100).length})
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginLeft: '70px', marginRight: '20px' }}
        >
          <IconButton
            color="inherit"
            onClick={handlePreviousWeek}
            sx={{
              p: 0,
              width: 67,
              height: 60,
              backgroundColor: '#438FFE',
              paddingLeft: '29px',
              paddingRight: '23px',
              paddingTop: '23px',
              paddingBottom: '23px',
              borderRadius: '9px',
              '&:hover': {
                backgroundColor: '#438FFE'
              },
              '&:active': {
                backgroundColor: '#438FFE'
              }
            }}
          >
            <ArrowBackIosIcon
              sx={{
                fontSize: 24,

                color: 'white'
              }}
            />
          </IconButton>
          <Button
            variant="contained"
            style={{
              marginLeft: 8,
              marginRight: 8,
              height: 60,
              width: 'auto',

              borderRadius: 9,
              backgroundColor: '#f3f3f3'
            }}
            sx={{
              '&:hover': {
                backgroundColor: '#f3f3f3',
                color: '#000'
              },
              '&:active': {
                backgroundColor: '#f3f3f3',
                color: '#000'
              },
              boxShadow: 'none'
            }}
            onClick={handleCurrentWeek}
          >
            {/* Current Week {weekRange && `(${weekRange})`} */}
            <Typography
              sx={{
                flexWrap: 'nowrap',
                fontSize: '18px',
                fontWeight: 400,
                color: '#000',
                whiteSpace: 'nowrap'
              }}
            >
              Current Week
            </Typography>
            <Typography
              sx={{
                flexWrap: 'nowrap',
                fontSize: '18px',
                fontWeight: 400,
                color: '#A9A9A9',
                marginLeft: '5px',
                whiteSpace: 'nowrap'
              }}
            >
              {weekRange && `(${weekRange})`}
            </Typography>
          </Button>

          <IconButton
            color="inherit"
            onClick={handleNextWeek}
            sx={{
              p: 0,
              width: 67,
              height: 60,
              backgroundColor: '#438FFE',
              paddingLeft: '29px',
              paddingRight: '23px',
              paddingTop: '23px',
              paddingBottom: '23px',
              borderRadius: '9px',
              '&:hover': {
                backgroundColor: '#438FFE'
              },
              '&:active': {
                backgroundColor: '#438FFE'
              }
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: 24,

                color: 'white'
              }}
            />
          </IconButton>
        </Box>
      </Box>

      {/* Second PArt */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          overflowX: 'hidden'
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            marginX: { xs: '10px', sm: '20px', md: '117px' },
            overflowX: 'auto',
            overflowY: 'auto',
            maxHeight: '500px'
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableRow>
              <StyledTableCell
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'Inter',
                  whiteSpace: 'nowrap'
                }}
              >
                Student Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontSize: '14px', fontWeight: 500, fontFamily: 'Inter' }}>
                Points
              </StyledTableCell>
              {weekDates.map((dateStr, index) => {
                // const date = new Date(dateStr);

                const date = moment.tz(dateStr, 'YYYY-MM-DD', 'UTC').toDate();

                const dayName = format(date, 'EEEE');

                const isDisplayed = data ? data[dayName.toLocaleLowerCase()] : false;

                const titles = homeworkList.map((h: any) => h.title);
                if (isDisplayed) {
                  const formattedDate = format(date, 'yyyy-MM-dd');

                  const titlesForDate = titleByDate[formattedDate] || [];

                  const chaptersForDate = chapterByDate[formattedDate] || [];

                  return (
                    <StyledTableCell key={index} align="right">
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{format(date, 'EEE MM/dd')}</span>
                          <AddCircle
                            onClick={() => handleOpenCreateForm(formattedDate)}
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                          />
                        </div> */}

                        {titlesForDate.length > 0 && chaptersForDate.length > 0 && (
                          <div style={{ marginTop: '4px' }}>
                            {titlesForDate.map((title: { title: any; id: any }, idx: number) => (
                              // <Box
                              //   key={idx}
                              //   display="flex"
                              //   alignItems="center"
                              //   flexWrap="wrap"
                              //   mb={1}
                              //   mt={1}
                              //   sx={{
                              //     backgroundColor: '#438FFE',
                              //     color: 'white',
                              //     padding: ' 12px',
                              //     borderRadius: '56px',
                              //     fontWeight: 'bold',
                              //     marginRight: '8px'
                              //   }}
                              // >
                              //   <Typography>{format(date, 'EEE MM/dd')}</Typography>
                              //   {chaptersForDate[idx] && (
                              //     <Typography
                              //       variant="body2"
                              //       onClick={() => handleEditHomework(title.id)}
                              //       sx={{
                              //         fontSize: '14px',
                              //         fontFamily: 'Inter',
                              //         fontWeight: 500,
                              //         textAlign: 'center',
                              //         whiteSpace: 'nowrap'
                              //       }}
                              //     >
                              //       {title.title} - Chapter {chaptersForDate[idx].chapter}
                              //     </Typography>
                              //   )}

                              //   <Icon
                              //     icon={edit2Outline}
                              //     style={{
                              //       marginLeft: '8px',
                              //       cursor: 'pointer',
                              //       height: 20,
                              //       width: 20
                              //     }}
                              //     onClick={() => handleEditHomework(title.id)}
                              //   />
                              //   <ContentCopyIcon
                              //     onClick={() => handleDuplicateHomework(title.id)}
                              //     style={{ marginLeft: '8px', cursor: 'pointer' }}
                              //     fontSize="small"
                              //   />
                              // </Box>
                              <Box
                                key={idx}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                mb={1}
                                mt={1}
                                sx={{
                                  backgroundColor: '#438FFE',
                                  color: 'white',

                                  paddingLeft: '12px',
                                  paddingRight: '12px',
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                  borderRadius: '56px',
                                  fontWeight: 'bold',
                                  marginRight: '8px'
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      marginBottom: '4px',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {format(date, 'EEE MM/dd')}
                                  </Typography>

                                  {chaptersForDate[idx] && (
                                    <Typography
                                      variant="body2"
                                      onClick={() => handleEditHomework(title.id)}
                                      sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      {title.title} - Chapter {chaptersForDate[idx].chapter}
                                    </Typography>
                                  )}
                                </Box>
                                <Icon
                                  icon={edit2Outline}
                                  style={{
                                    marginLeft: '8px',
                                    cursor: 'pointer',
                                    height: 20,
                                    width: 20
                                  }}
                                  onClick={() => handleEditHomework(title.id)}
                                />
                                <ContentCopyIcon
                                  onClick={() => handleDuplicateHomework(title.id)}
                                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                                  fontSize="small"
                                />
                                {/* Edit and Duplicate Icons */}
                              </Box>
                            ))}
                          </div>
                        )}
                      </Box>
                    </StyledTableCell>
                  );
                } else {
                  return null;
                }
              })}
            </TableRow>

            <TableBody>
              {rows.map((row: any, rowIndex: any) => {
                const { totalAbsences, totalPoints } = weekDates.reduce(
                  (accumulated, date) => {
                    const dayName = format(new Date(date), 'EEEE');
                    const isDisplayed = data ? data[dayName.toLocaleLowerCase()] : false;

                    if (isDisplayed) {
                      const formattedDate = format(new Date(date), 'yyyy-MM-dd');

                      const pointData = row.points[formattedDate];

                      if (pointData && pointData.point) {
                        if (pointData.point === 'A') {
                          accumulated.totalAbsences += 1;
                        } else {
                          accumulated.totalPoints += parseFloat(pointData.point);
                        }
                      }
                    }

                    return accumulated;
                  },
                  { totalAbsences: 0, totalPoints: 0 }
                );

                return (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 500,
                          fontFamily: 'Inter',
                          lineHeight: '26px'
                        }}
                      >
                        {row.fullName}
                      </Typography>

                      {/* {totalAbsences > 0 && (
                      <span
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          padding: '10px',
                          marginLeft: '10px'
                        }}
                      >
                        {totalAbsences.toFixed(0)}
                      </span>
                    )}

                    {ProgressData && (
                      <div style={{ marginTop: '10px', width: '100%' }}>
                        <div
                          style={{
                            backgroundColor: '#e0e0e0',
                            borderRadius: '4px',
                            height: '20px',
                            width: '100%',
                            overflow: 'hidden',
                            position: 'relative'
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#438FFE',
                              height: '100%',
                              width: `${
                                ProgressData.find(
                                  (student: any) => student.id === row.id
                                )?.averageProgress?.toFixed(2) || 0
                              }%`,
                              borderRadius: '4px'
                            }}
                          >
                            <span
                              style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'black',
                                fontWeight: 'bold'
                              }}
                            >
                              {ProgressData.find(
                                (student: any) => student.id === row.id
                              )?.totalEarnedPoints.toFixed(0)}{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                    )} */}
                    </StyledTableCell>
                    <StyledTableCell style={{ display: 'flex' }}>
                      <Box
                        sx={{
                          display: 'inline-block',
                          backgroundColor: '#f3f3f3',
                          fontSize: '18px',
                          fontFamily: 'Inter',
                          fontWeight: 400,
                          lineHeight: '26px',
                          padding: '10px',
                          borderRadius: '9px',
                          minWidth: '50px',
                          textAlign: 'center',
                          overflow: 'hidden'
                        }}
                      >
                        {progressPoint
                          .find((student: any) => student.id === row.id)
                          ?.totalEarnedPoints.toFixed(0)}
                      </Box>

                      {progressPoint
                        .filter((progress: any) => progress.id === row.id)
                        .map((progress: any) => (
                          <Box
                            key={progress.id}
                            sx={{
                              height: '8px',
                              borderRadius: '5px',
                              overflow: 'hidden',
                              flexGrow: 1,
                              marginTop: '20px',
                              marginLeft: '17px'
                            }}
                          >
                            <Box
                              sx={{
                                width:
                                  progress.totalEarnedPoints >= 100
                                    ? '240px'
                                    : progress.totalEarnedPoints >= 50
                                    ? '120px'
                                    : '40px',
                                height: '100%',
                                backgroundColor:
                                  progress.totalEarnedPoints > 100
                                    ? 'green'
                                    : progress.totalEarnedPoints > 50
                                    ? 'orange'
                                    : 'red'
                              }}
                            />
                          </Box>
                        ))}
                    </StyledTableCell>
                    {weekDates.map((date, dateIndex) => {
                      const dayName = format(new Date(date), 'EEEE');

                      const isDisplayed = data ? data[dayName.toLocaleLowerCase()] : false;

                      if (isDisplayed) {
                        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
                        const titlesForDate = titleByDate[formattedDate] || [];
                        console.log('Tilte', titlesForDate);
                        const pointData = row.points[formattedDate];

                        return (
                          <StyledTableCell
                            key={dateIndex}
                            onClick={() => handleCellClick(rowIndex, dateIndex)}
                          >
                            {editCell.rowIndex === rowIndex && editCell.dateIndex === dateIndex ? (
                              <TextField
                                variant="outlined"
                                size="small"
                                defaultValue={pointData?.point || ''}
                                onChange={handleInputChange}
                                onBlur={() => handleInputBlur(rowIndex, dateIndex)}
                                autoFocus
                                sx={{
                                  // maxWidth: 50,
                                  width: '60px',
                                  height: '50px',
                                  marginLeft: '60px',
                                  backgroundColor: '#F3F3F3',
                                  borderRadius: '9px',
                                  textAlign: 'center',
                                  '& .MuiInputBase-root': {
                                    padding: '5px'
                                  }
                                }}
                              />
                            ) : pointData ? (
                              <Box
                                component="span"
                                sx={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline'
                                }}
                                onClick={() => handleCellClick(rowIndex, dateIndex)}
                              >
                                <Box
                                  sx={{
                                    width: '50px',
                                    height: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #F3F3F3',
                                    backgroundColor: '#F3F3F3',
                                    borderRadius: '9px',
                                    textAlign: 'center',
                                    padding: '10px',
                                    marginLeft: '60px'
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    sx={{
                                      fontSize: '18px',
                                      fontFamily: 'Inter',
                                      fontWeight: 400,
                                      lineHeight: '26px'
                                    }}
                                  >
                                    {pointData.point}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : // titlesForDate && titlesForDate.title ? (
                            //   <Box
                            //     sx={{
                            //       width: '50px',
                            //       height: '30px',
                            //       display: 'flex',
                            //       alignItems: 'center',
                            //       justifyContent: 'center',
                            //       border: '1px solid #000',
                            //       borderRadius: '5px'
                            //     }}
                            //   ></Box>
                            Array.isArray(titlesForDate) &&
                              titlesForDate.length > 0 &&
                              titlesForDate[0].title ? (
                              <Box
                                sx={{
                                  width: '50px',
                                  height: '50px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  border: '1px solid #F3F3F3',
                                  backgroundColor: '#F3F3F3',
                                  borderRadius: '9px',
                                  textAlign: 'center',
                                  padding: '10px',
                                  marginLeft: '60px'
                                }}
                              />
                            ) : null}
                          </StyledTableCell>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {openHomeworkForm && selectedHomeworkId !== '' ? (
        <EditHomeworkForm
          editId={+selectedHomeworkId}
          classId={editId}
          openForm={openHomeworkForm}
          formClosed={handleHomeworkFormClosed}
          processedSuccess={handleHomeworkFormProcessedSuccess}
          onDelete={() => handleDeleteHomework(selectedHomeworkId)}
        />
      ) : (
        <HomeworkForm
          openForm={openHomeworkForm}
          editId={editId}
          dates={selectedDate}
          formClosed={handleHomeworkFormClosed}
          processedSuccess={handleHomeworkFormProcessedSuccess}
        />
      )}
      {openDelConf && (
        <HomeworkDeleteConfirmationDialog
          openDialog={openDelConf}
          actionCancelled={handleDeletingCancelled}
          actionDeleted={handleDeleted}
          recordId={selectedHomeworkId}
        />
      )}
      {/* {openGradeForms && (
        <GradeForm
          openForm={openGradeForms}
          formClosed={handleGradeFormClosed}
          processedSuccess={handleGradeFormProcessedSuccess}
        />
      )} */}

      {openEditGradeForm && selectedGradeId && (
        <EditGradeForm
          editId={selectedGradeId}
          openForm={openEditGradeForm}
          formClosed={handleEditGradeFormClosed}
          processedSuccess={handleGradeFormProcessedSuccess}
        />
      )}
      {openDuplicateForm && selectedDuplicateHomeworkId && (
        <DuplicateHomeeworkForm
          classId={editId}
          editId={+selectedDuplicateHomeworkId}
          openForm={openDuplicateForm}
          formClosed={handleDuplicateHomeworkForm}
          processedSuccess={handleHomeworkFormProcessedSuccess}
        />
      )}
    </LocalizationProvider>
  );
}
