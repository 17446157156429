import { Box, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { RootState } from 'redux/store';
import { useParams } from 'react-router';
import { decode } from 'base-64';
import { getHighestStudentProgress } from 'redux/slices/director';

const StudentrankList = () => {
  const dispatch = useDispatch();
  const { schoolId }: any = useParams();
  console.log('Params', schoolId);

  const decryptedSchoolId: any = decode(schoolId);

  console.log('Decoded Record ID:', decryptedSchoolId);

  const { studentProgress } = useSelector((state: RootState) => state.director);
  console.log('StudentProgress', studentProgress);

  useEffect(() => {
    const hightststudent = async () => {
      await dispatch(getHighestStudentProgress(decryptedSchoolId));
    };
    hightststudent();
  }, []);

  const CircularProgressWithSegments = ({
    percentage0to50,
    percentage50to100,
    percentage100Plus
  }: {
    percentage0to50: number;
    percentage50to100: number;
    percentage100Plus: number;
  }) => {
    const totalPercentage = percentage0to50 + percentage50to100 + percentage100Plus;
    const gap = 5;
    if (totalPercentage !== 100) {
      console.error('Total percentage must equal 100');
    }

    const getDashArray = (percentage: number) => {
      return (percentage / 100) * 2 * Math.PI * 50;
    };

    const redDashArray = getDashArray(percentage0to50);
    const orangeDashArray = getDashArray(percentage50to100);
    const greenDashArray = getDashArray(percentage100Plus);

    return (
      <svg width="92" height="92" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="50" stroke="#fff" strokeWidth="10" fill="transparent" />
        {/* Red */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="red"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${redDashArray} ${2 * Math.PI * 50 - redDashArray}`}
          strokeDashoffset="90"
        />
        {/* Orange  */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="orange"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${orangeDashArray} ${2 * Math.PI * 50 - orangeDashArray}`}
          strokeDashoffset={`${90 - redDashArray}`}
          // strokeDashoffset={`${90 - redDashArray - gap}`}
        />
        {/* Green */}
        <circle
          cx="60"
          cy="60"
          r="50"
          stroke="green"
          strokeWidth="15"
          fill="transparent"
          strokeDasharray={`${greenDashArray} ${2 * Math.PI * 50 - greenDashArray}`}
          strokeDashoffset={`${90 - redDashArray - orangeDashArray}`}
          // strokeDashoffset={`${90 - redDashArray - orangeDashArray - gap * 2}`}
        />
      </svg>
    );
  };

  const calculatePercentage = (min: any, max: any) => {
    const count = studentProgress.filter(
      (student) => student.totalPoints >= min && student.totalPoints <= max
    ).length;
    return ((count / studentProgress.length) * 100).toFixed(2);
  };

  const percentage0to50 = calculatePercentage(0, 50);

  const percentage50to100 = calculatePercentage(51, 100);

  const percentage100Plus = calculatePercentage(101, Infinity);

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f8f8f8' }}>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 500,
          marginLeft: { xs: '20px', sm: '120px' },
          marginTop: '50px'
        }}
      >
        Progress
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',

          marginX: { xs: '10px', sm: '20px', md: '117px' },
          overflowX: 'auto',
          overflowY: 'auto',
          marginTop: '36px',
          // flexWrap: 'nowrap',
          marginBottom: '10px',
          // overflow: 'hidden',
          borderRadius: '9px'
          // flexWrap: 'wrap'
        }}
      >
        {/* Circle Progress */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '23px',
            marginLeft: '24px',
            marginTop: '28px'
          }}
        >
          <CircularProgressWithSegments
            percentage0to50={parseFloat(percentage0to50)}
            percentage50to100={parseFloat(percentage50to100)}
            percentage100Plus={parseFloat(percentage100Plus)}
          />
        </Box>

        {/* Total student and Point */}
        <Box
          sx={{
            alignItems: 'center',

            fontWeight: 'bold',

            marginTop: { xs: '10px', sm: '20px' },
            marginLeft: { xs: '8px', sm: '13px' }
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: { xs: '8px', sm: '16px' },
              fontSize: { xs: '20px', sm: '24px' },
              textAlign: 'center',
              fontWeight: 500,
              fontFamily: 'Inter, sans-serif',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {studentProgress.reduce((sum: any, progress: any) => sum + progress.totalPoints, 0)}{' '}
            points
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '20px',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Inter, sans-serif'
            }}
          >
            {studentProgress.length} Students
          </Typography>
        </Box>

        <Box
          sx={{
            borderLeft: '2px solid #D9D9D9',
            height: '100px',
            marginTop: { xs: '0px', sm: '15px' },
            marginBottom: { xs: '0px', sm: '15px' },
            marginLeft: '60px',
            display: 'inline-block'
          }}
        />
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '9px',
            // marginX: '117px',
            padding: '16px'
          }}
        >
          {/* 0-50 points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'red',
                borderWidth: '1px',
                marginTop: '5px',

                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '25px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              0-50 points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '10px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage0to50}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              (
              {
                studentProgress.filter(
                  (progress: any) => progress.totalPoints >= 0 && progress.totalPoints <= 50
                ).length
              }
              )
            </Typography>
          </Box>

          {/* 50-100 points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'orange',
                borderWidth: '1px',
                marginTop: '5px',
                marginRight: '7px',
                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '20px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              50-100 points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '25px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage50to100}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              (
              {
                studentProgress.filter(
                  (progress: any) => progress.totalPoints > 50 && progress.totalPoints <= 100
                ).length
              }
              )
            </Typography>
          </Box>

          {/* 100+ points */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'green',
                borderWidth: '1px',
                marginTop: '5px',
                // marginRight: '3px',
                borderRadius: '10px',
                width: '6px',
                height: '6px'
              }}
            ></Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '30px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              100+ points:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '10px',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'center'
              }}
            >
              {percentage100Plus}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                textAlign: 'right'
              }}
            >
              ({studentProgress.filter((progress: any) => progress.totalPoints > 100).length})
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '9px',
          // marginX: '117px',
          marginX: { xs: '10px', sm: '20px', md: '117px' },
          overflowX: 'auto',
          overflowY: 'auto',
          marginTop: '8px',
          padding: '16px'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '24px',

              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Inter',
              marginTop: '18px',
              marginBottom: '18px',
              textAlign: 'center'
            }}
          >
            StudentName
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '50%',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Inter',
              marginTop: '18px',
              marginBottom: '18px',
              textAlign: 'center'
            }}
          >
            Points
          </Typography>
        </Box>

        <Box
          component="hr"
          sx={{
            border: '1px solid #EAEAEA',
            marginBottom: '18px'
          }}
        />

        {/* {studentProgress.map((student: any) => (
          <Box key={student.studentId}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px'
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  flexShrink: 0,
                  lineHeight: '1.9',
                  fontSize: '18px',
                  fontWeight: 400,
                  fontFamily: 'Inter',
                  marginLeft: '16px',
                  textAlign: 'center',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {student.studentName}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  marginLeft: '70px'
                  // marginRight: '16px'
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    flexShrink: 0,
                    lineHeight: '1.9',
                    fontSize: '18px',
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    textAlign: 'center',
                    backgroundColor: '#f3f3f3',
                    borderRadius: '9px',
                    padding: '10px',
                    whiteSpace: 'nowrap',
                    minWidth: '50px',
                    marginRight: '12px'
                  }}
                >
                  {student.totalPoints}
                </Typography>

                <Box
                  sx={{
                    height: '8px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    flexGrow: 1,
                    marginRight: '20px'
                  }}
                >
                  <Box
                    sx={{
                      width:
                        student.totalPoints >= 100
                          ? '240px'
                          : student.totalPoints >= 50
                          ? '120px'
                          : '40px',
                      height: '100%',
                      backgroundColor:
                        student.totalPoints >= 100
                          ? 'green'
                          : student.totalPoints >= 50
                          ? 'orange'
                          : 'red'
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              component="hr"
              sx={{
                border: '1px solid #EAEAEA',
                marginBottom: '18px'
              }}
            />
          </Box>
        ))} */}
        {studentProgress.map((student: any) => (
          <Box key={student.studentId}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '12px'
              }}
            >
              {/* Student Name */}
              <Typography
                variant="subtitle1"
                sx={{
                  flexBasis: '50%',
                  flexShrink: 0,
                  lineHeight: '1.9',
                  fontSize: '18px',
                  fontWeight: 400,
                  fontFamily: 'Inter',

                  whiteSpace: 'nowrap'
                  // textOverflow: 'ellipsis'
                }}
              >
                {student.studentName}
              </Typography>

              {/* Points and Progress */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexGrow: 1 // Allocate the remaining space
                }}
              >
                {/* Points */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: '1.9',
                    fontSize: '18px',
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    textAlign: 'center',
                    backgroundColor: '#f3f3f3',
                    borderRadius: '9px',
                    padding: '10px',
                    minWidth: '70px', // Ensure consistent width for points
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {student.totalPoints}
                </Typography>

                {/* Progress Bar */}
                <Box
                  sx={{
                    height: '8px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    flexGrow: 1, // Expand to fill remaining space
                    marginLeft: '12px',
                    marginRight: '20px'
                  }}
                >
                  <Box
                    sx={{
                      width:
                        student.totalPoints > 100
                          ? '100%' // Scale based on container
                          : student.totalPoints > 50
                          ? '50%'
                          : '20%',
                      height: '100%',
                      backgroundColor:
                        student.totalPoints >= 100
                          ? 'green'
                          : student.totalPoints >= 50
                          ? 'orange'
                          : 'red'
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Separator */}
            <Box
              component="hr"
              sx={{
                border: '1px solid #EAEAEA',
                marginBottom: '18px'
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StudentrankList;
